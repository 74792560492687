/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const voyage: Record<string, TVoyage> = {
  'en-AU': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'No results. Try adjusting your search by removing filters',
        pageTitle: 'Choose Your Cruise',
        showingXExpeditionCruises: 'Showing {number} expedition cruise(s)',
        sortFeatured: 'Featured',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Price (low to high)',
        sortPriceDesc: 'Price (high to low)',
        somethingWentWrong: 'Something went wrong, please try again later',
        tryOurMustSeeDestinations: 'Or try our must-see destinations',
        advancedFilters: 'Advanced filters',
        xSelectedFilters: '{filters} selected filters',
        seeMoreCruises: 'See more expedition cruises',
        xOfYCruises: '{x} of {y} cruises',
        sortDepartureASC: 'Departure date (near to far)',
        sortDepartureDESC: 'Departure date (far to near)',
        pageSubtitle: 'PLEASE USE THE DESTINATION FILTER BELOW TO NARROW DOWN YOUR SEARCH - THANK YOU!',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Related cruises',
        shipFallbackTitle: 'Ships on this expedition',
        seeShip: 'See ship',
        unknownDestination: 'Unknown destination',
        destinationsForExplorers: 'Destinations for explorers',
        destinationsForExplorersSubtitle: 'A mix of pristine wilderness and visits to genuine local communities.',
        failedToLoad: 'Failed to load voyage. Please try again later.',
        explorationTeamCaption: 'Your onboard guides are local experts in areas of interest specific to {destination}',
        whatIsIncluded: 'What\'s included',
        availablePromotions: 'Available Promotions',
        departureDates: 'Departure Dates',
        departuresBetween: '{numberOfDepartures} departures between {startDate} and {endDate}',
        departureDate: 'Departure date',
        includedActivities: {
          title: 'Included Programmes',
          subTitle: 'It\'s a mixture of adventure activities for individuals of all ages'
        },
        returnDate: 'Return date',
        practicalInfoYouNeedToKnow: 'Practical information you need to know',
        enhanceYourCruise: {
          title: 'Enhance your cruise',
          subTitle: 'Learn more about what you can experience when going with us',
          excursionTab: 'Excursions',
          programTab: 'Pre-post programmes'
        },
        lookForwardToCruise: 'Take a look at what you can look forward to on this expedition cruise.',
        lookForwardToNDayCruise: 'Take a look at what you can look forward to on this {duration}-day expedition cruise.',
        specialOffers: 'Special offers',
        expeditionExclusiveCostSavings: 'Expedition exclusive cost savings and rare opportunities.',
        exclusiveCostSavingsFootnote: 'Certain offers may not be combinable, up to two savings opportunities except where noted otherwise',
        shipSectionDescriptionText: 'Take a closer look at our ships typically used on this expedition',
        requestAQuote: 'Request a quote',
        startBookingAt: 'Start booking {date}',
        moreDepartures: 'more departures',
        moreDeparture: 'more departure',
        cruiseOverview : 'Cruise Overview',
        excursionsActivities: 'Excursions & Activities ',
        itinerary: 'Itinerary',
        whatsIncluded: 'What\'s Included',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'From 2024, all sailings on MS Nordstjernen will be operated by Hurtigruten Norway. '
      },
      onboardActivities: 'Onboard activities',
      landingActivities: 'Landing activities',
      whatsNotIncluded: 'What\'s not included',
      itinerary: {
        selectTheDay: 'Select the day',
        showRouteMap: 'Show Route Map',
        showGallery: 'Show gallery',
        checkDayToDayItinerary: 'Check day-to-day itinerary',
        routeOverview: 'Route overview',
        routeOverviewDescription: 'We\'ll help you plan where to go, when to visit and how long to stay'
      },
      temporarilyDisabled: {
        heading: 'Departure dates temporarily unavailable',
        sorry: 'We’re sorry, but we are performing maintenance until September 13th, which affects your account, cruises and other features.',
        contact: 'For help, please contact Customer Services on +61 1300159127'
      }
    }
  },
  'en-GB': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'No results. Try adjusting your search by removing filters',
        pageTitle: 'Find Your Cruise',
        showingXExpeditionCruises: 'Showing {number} expedition cruise(s)',
        sortFeatured: 'Featured',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Price (low to high)',
        sortPriceDesc: 'Price (high to low)',
        somethingWentWrong: 'Something went wrong, please try again later',
        tryOurMustSeeDestinations: 'Or try our must-see destinations',
        advancedFilters: 'Advanced filters',
        xSelectedFilters: '{filters} selected filters',
        seeMoreCruises: 'See more expedition cruises',
        xOfYCruises: '{x} of {y} cruises',
        sortDepartureASC: 'Departure date (near to far)',
        sortDepartureDESC: 'Departure date (far to near)',
        pageSubtitle: 'We have many cruises to several exciting destinations. Use the destination filter to see where you can go.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Related cruises',
        shipFallbackTitle: 'Ships on this expedition',
        seeShip: 'See ship',
        unknownDestination: 'Unknown destination',
        destinationsForExplorers: 'Destinations for explorers',
        destinationsForExplorersSubtitle: 'A mix of pristine wilderness and visits to genuine local communities.',
        failedToLoad: 'Failed to load voyage. Please try again later.',
        explorationTeamCaption: 'Your onboard guides are local experts in areas of interest specific to {destination}',
        whatIsIncluded: 'What\'s included',
        availablePromotions: 'Available Promotions',
        departureDates: 'Departure Dates',
        departuresBetween: '{numberOfDepartures} departures between {startDate} and {endDate}',
        departureDate: 'Departure date',
        includedActivities: {
          title: 'Included Programmes',
          subTitle: 'It\'s a mixture of adventure activities for individuals of all ages'
        },
        returnDate: 'Return date',
        practicalInfoYouNeedToKnow: 'Practical information you need to know',
        enhanceYourCruise: {
          title: 'Enhance your cruise',
          subTitle: 'Learn more about what you can experience when going with us',
          excursionTab: 'Excursions',
          programTab: 'Pre-post programmes'
        },
        lookForwardToCruise: 'Take a look at what you can look forward to on this expedition cruise.',
        lookForwardToNDayCruise: 'Take a look at what you can look forward to on this {duration}-day expedition cruise.',
        specialOffers: 'Special offers',
        expeditionExclusiveCostSavings: 'Expedition exclusive cost savings and rare opportunities.',
        exclusiveCostSavingsFootnote: 'Certain offers may not be combinable, up to two savings opportunities except where noted otherwise',
        shipSectionDescriptionText: 'Take a closer look at our ships typically used on this expedition',
        requestAQuote: 'Request a quote',
        startBookingAt: 'Start booking {date}',
        moreDepartures: 'more departures',
        moreDeparture: 'more departure',
        cruiseOverview : 'Cruise Overview',
        excursionsActivities: 'Excursions & Activities ',
        itinerary: 'Itinerary',
        whatsIncluded: 'What\'s Included',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'From 2024, all sailings on MS Nordstjernen will be operated by Hurtigruten Norway. '
      },
      onboardActivities: 'Onboard activities',
      landingActivities: 'Landing activities',
      whatsNotIncluded: 'What\'s not included',
      itinerary: {
        selectTheDay: 'Select the day',
        showRouteMap: 'Show Route Map',
        showGallery: 'Show gallery',
        checkDayToDayItinerary: 'Check day-to-day itinerary',
        routeOverview: 'Route overview',
        routeOverviewDescription: 'We\'ll help you plan where to go, when to visit and how long to stay'
      },
      temporarilyDisabled: {
        heading: 'Departure dates temporarily unavailable',
        sorry: 'We’re sorry, but we are performing maintenance until September 13th, which affects your account, cruises and other features.',
        contact: 'For help, please contact Customer Services on +44 2036959973'
      }
    }
  },
  'en-US': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'No results. Try adjusting your search by removing filters',
        pageTitle: 'Find Your Cruise',
        showingXExpeditionCruises: 'Showing {number} expedition cruise(s)',
        sortFeatured: 'Featured',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Price (low to high)',
        sortPriceDesc: 'Price (high to low)',
        somethingWentWrong: 'Something went wrong, please try again later',
        tryOurMustSeeDestinations: 'Or try our must-see destinations',
        advancedFilters: 'Advanced filters',
        xSelectedFilters: '{filters} selected filters',
        seeMoreCruises: 'See more expedition cruises',
        xOfYCruises: '{x} of {y} cruises',
        sortDepartureASC: 'Departure date (near to far)',
        sortDepartureDESC: 'Departure date (far to near)',
        pageSubtitle: 'We have many cruises to several exciting destinations. Use the destination filter to see where you can go.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Related cruises',
        shipFallbackTitle: 'Ships on this expedition',
        seeShip: 'See ship',
        unknownDestination: 'Unknown destination',
        destinationsForExplorers: 'Destinations for explorers',
        destinationsForExplorersSubtitle: 'A mix of pristine wilderness and visits to genuine local communities.',
        failedToLoad: 'Failed to load voyage. Please try again later.',
        explorationTeamCaption: 'Your onboard guides are local experts in areas of interest specific to {destination}',
        whatIsIncluded: 'What\'s included',
        availablePromotions: 'Available Promotions',
        departureDates: 'Departure Dates',
        departuresBetween: '{numberOfDepartures} departures between {startDate} and {endDate}',
        departureDate: 'Departure date',
        includedActivities: {
          title: 'Included Programs',
          subTitle: 'It\'s a mixture of adventure activities for individuals of all ages'
        },
        returnDate: 'Return date',
        practicalInfoYouNeedToKnow: 'Practical information you need to know',
        enhanceYourCruise: {
          title: 'Enhance your cruise',
          subTitle: 'Learn more about what you can experience when going with us',
          excursionTab: 'Excursions',
          programTab: 'Pre-post programs'
        },
        lookForwardToCruise: 'Take a look at what you can look forward to on this expedition cruise.',
        lookForwardToNDayCruise: 'Take a look at what you can look forward to on this {duration}-day expedition cruise.',
        specialOffers: 'Special offers',
        expeditionExclusiveCostSavings: 'Expedition exclusive cost savings and rare opportunities.',
        exclusiveCostSavingsFootnote: 'Certain offers may not be combinable, up to two savings opportunities except where noted otherwise',
        shipSectionDescriptionText: 'Take a closer look at our ships typically used on this expedition',
        requestAQuote: 'Request a quote',
        startBookingAt: 'Start booking {date}',
        moreDepartures: 'more departures',
        moreDeparture: 'more departure',
        cruiseOverview : 'Cruise Overview',
        excursionsActivities: 'Excursions & Activities ',
        itinerary: 'Itinerary',
        whatsIncluded: 'What\'s Included',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'From 2024, all sailings on MS Nordstjernen will be operated by Hurtigruten Norway. '
      },
      onboardActivities: 'Onboard activities',
      landingActivities: 'Landing activities',
      whatsNotIncluded: 'What\'s not included',
      itinerary: {
        selectTheDay: 'Select the day',
        showRouteMap: 'Show Route Map',
        showGallery: 'Show gallery',
        checkDayToDayItinerary: 'Check day-to-day itinerary',
        routeOverview: 'Route overview',
        routeOverviewDescription: 'We\'ll help you plan where to go, when to visit and how long to stay'
      },
      temporarilyDisabled: {
        heading: 'Departure dates temporarily unavailable',
        sorry: 'We’re sorry, but we are performing maintenance until September 13th, which affects your account, cruises and other features.',
        contact: 'For help, please contact Customer Services on +18 885689793'
      }
    }
  },
  'de-DE': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Keine Ergebnisse. Versuchen Sie, Ihre Suche durch Entfernen von Filtern anzupassen',
        pageTitle: 'Finden Sie Ihre Seereise',
        showingXExpeditionCruises: 'Es werden {number} Expeditions-Seereisen angezeigt',
        sortFeatured: 'Ausgewählt',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Preis (niedrigster Preis zuerst)',
        sortPriceDesc: 'Preis (höchster Preis zuerst)',
        somethingWentWrong: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal',
        tryOurMustSeeDestinations: 'Oder entdecken Sie unsere Reiseziele',
        advancedFilters: 'Erweiterte Filter',
        xSelectedFilters: '{filters} ausgewählte Filter',
        seeMoreCruises: 'Weitere Expeditions-Seereisen anzeigen',
        xOfYCruises: '{x} von {y} Seereisen',
        sortDepartureASC: 'Reisetermin (frühester zuerst)',
        sortDepartureDESC: 'Reisetermin (spätester zuerst)',
        pageSubtitle: 'Verwenden Sie den Zielortfilter (Reiseziele) folgend und grenzen Sie Ihre Suche darüber ein.',
        sort: 'Sortierung '
      },
      details: {
        relatedVoyagesTitle: 'Ähnliche Seereisen',
        shipFallbackTitle: 'Schiffe auf dieser Expeditionsreise',
        seeShip: 'Schiff ansehen',
        unknownDestination: 'Unbekanntes Reiseziel',
        destinationsForExplorers: 'Reiseziele für echte Entdecker',
        destinationsForExplorersSubtitle: 'Eine Mischung aus unberührter Wildnis und Besuchen authentischer, lokaler Gemeinden vor Ort.',
        failedToLoad: 'Die Reise konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
        explorationTeamCaption: 'Ihre Reiseleiter an Bord sind lokale Experten für spezifische Interessengebiete in {destination}',
        whatIsIncluded: 'Im Preis inbegriffen',
        availablePromotions: 'Verfügbare Angebote',
        departureDates: 'Abreisedaten',
        departuresBetween: '{numberOfDepartures} Abfahrten zwischen {startDate} und {endDate}',
        departureDate: 'Abreisedatum',
        includedActivities: {
          title: 'Im Reisepreis enthaltene Programme',
          subTitle: 'Auf Sie wartet eine Vielzahl von abenteuerlichen Aktivitäten für Menschen jeden Alters'
        },
        returnDate: 'Rückreisedatum',
        practicalInfoYouNeedToKnow: 'Praktische Informationen, die Sie wissen müssen',
        enhanceYourCruise: {
          title: 'Optimieren Sie Ihre Seereise',
          subTitle: 'Erfahren Sie mehr darüber, was Sie erleben können, wenn Sie mit uns reisen',
          excursionTab: 'Ausflüge',
          programTab: 'Vor- und Nachprogramme'
        },
        lookForwardToCruise: 'Ihre Reise mit Hurtigruten Expeditions: Expedition ohne Extrakosten.',
        lookForwardToNDayCruise: 'Werfen Sie einen Blick darauf, was Sie auf dieser {duration}-tägigen Expeditions-Seereise erwartet.',
        specialOffers: 'Spezial-Angebote',
        expeditionExclusiveCostSavings: 'Expeditionsreise exklusive Preissenkungen und besonderer Angebote.',
        exclusiveCostSavingsFootnote: 'Bestimmte Angebote sind möglicherweise nicht kombinierbar, bis zu zwei mögliche Ermäßigungen, außer wenn anders angegeben',
        shipSectionDescriptionText: 'Schauen Sie sich unsere Schiffe an, die typischerweise für diese Expeditionsreise eingesetzt werden',
        requestAQuote: 'Angebot anfordern',
        startBookingAt: 'Beginnen Sie mit der Buchung {date}',
        moreDepartures: 'weitere Reisetermine',
        moreDeparture: 'more departure',
        cruiseOverview : 'Ihre Reise in der Übersicht',
        excursionsActivities: 'Exkursionen & Aktivitäten',
        itinerary: 'Reiseplan',
        whatsIncluded: 'Was ist inbegriffen?',
        ottoSverdrup: 'Ab dem 19. Januar 2024 werden alle Reisen von MS Otto Sverdrup von Hurtigruten Norwegen durchgeführt.',
        nordstjernen: 'Ab 2024 werden alle Fahrten von MS Nordstjernen von Hurtigruten Norwegen durchgeführt.'
      },
      onboardActivities: 'Aktivitäten an Bord',
      landingActivities: 'Erkundungstouren',
      whatsNotIncluded: 'Nicht im Preis inbegriffen',
      itinerary: {
        selectTheDay: 'Wählen Sie den Tag aus',
        showRouteMap: 'Routenkarte ansehen',
        showGallery: 'Galerie anzeigen',
        checkDayToDayItinerary: 'Route von Tag zu Tag prüfen',
        routeOverview: 'Routenübersicht',
        routeOverviewDescription: 'Übersicht über den Reiseablauf'
      },
      temporarilyDisabled: {
        heading: 'Abreisedaten vorübergehend nicht verfügbar',
        sorry: 'Es tut uns leid, aber wir führen bis zum 13. September Wartungsarbeiten durch, die Ihr Konto, Ihre Kreuzfahrten und andere Funktionen betreffen.',
        contact: 'Wenn Sie Hilfe benötigen, wenden Sie sich bitte an den Kundendienst unter +49 4087405609'
      }
    }
  },
  'gsw-CH': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Keine Ergebnisse. Versuchen Sie, Ihre Suche durch Entfernen von Filtern anzupassen',
        pageTitle: 'Finden Sie Ihre Seereise',
        showingXExpeditionCruises: 'Es werden {number} Expeditions-Seereisen angezeigt',
        sortFeatured: 'Ausgewählt',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Preis (niedrigster Preis zuerst)',
        sortPriceDesc: 'Preis (höchster Preis zuerst)',
        somethingWentWrong: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal',
        tryOurMustSeeDestinations: 'Oder entdecken Sie unsere Reiseziele',
        advancedFilters: 'Erweiterte Filter',
        xSelectedFilters: '{filters} ausgewählte Filter',
        seeMoreCruises: 'Weitere Expeditions-Seereisen anzeigen',
        xOfYCruises: '{x} von {y} Seereisen',
        sortDepartureASC: 'Reisetermin (frühester zuerst)',
        sortDepartureDESC: 'Reisetermin (spätester zuerst)',
        pageSubtitle: 'Wählen Sie Ihr Wunsch-Reiseziel',
        sort: 'Sortierung '
      },
      details: {
        relatedVoyagesTitle: 'Ähnliche Seereisen',
        shipFallbackTitle: 'Schiffe auf dieser Expeditionsreise',
        seeShip: 'Schiff ansehen',
        unknownDestination: 'Unbekanntes Reiseziel',
        destinationsForExplorers: 'Reiseziele für echte Entdecker',
        destinationsForExplorersSubtitle: 'Eine Mischung aus unberührter Wildnis und Besuchen authentischer, lokaler Gemeinden vor Ort.',
        failedToLoad: 'Die Reise konnte nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
        explorationTeamCaption: 'Ihre Reiseleiter an Bord sind lokale Experten für spezifische Interessengebiete in {destination}',
        whatIsIncluded: 'Im Preis inbegriffen',
        availablePromotions: 'Verfügbare Angebote',
        departureDates: 'Abreisedaten',
        departuresBetween: '{numberOfDepartures} Abfahrten zwischen {startDate} und {endDate}',
        departureDate: 'Abreisedatum',
        includedActivities: {
          title: 'Im Reisepreis enthaltene Programme',
          subTitle: 'Auf Sie wartet eine Vielzahl von abenteuerlichen Aktivitäten für Menschen jeden Alters'
        },
        returnDate: 'Rückreisedatum',
        practicalInfoYouNeedToKnow: 'Praktische Informationen, die Sie wissen müssen',
        enhanceYourCruise: {
          title: 'Optimieren Sie Ihre Seereise',
          subTitle: 'Erfahren Sie mehr darüber, was Sie erleben können, wenn Sie mit uns reisen',
          excursionTab: 'Ausflüge',
          programTab: 'Vor- und Nachprogramme'
        },
        lookForwardToCruise: 'Ihre Reise mit Hurtigruten Expeditions: Expedition ohne Extrakosten.',
        lookForwardToNDayCruise: 'Werfen Sie einen Blick darauf, was Sie auf dieser {duration}-tägigen Expeditions-Seereise erwartet.',
        specialOffers: 'Spezial-Angebote',
        expeditionExclusiveCostSavings: 'Expeditionsreise exklusive Preissenkungen und besonderer Angebote.',
        exclusiveCostSavingsFootnote: 'Bestimmte Angebote sind möglicherweise nicht kombinierbar, bis zu zwei mögliche Ermäßigungen, außer wenn anders angegeben',
        shipSectionDescriptionText: 'Schauen Sie sich unsere Schiffe an, die typischerweise für diese Expeditionsreise eingesetzt werden',
        requestAQuote: 'Angebot anfordern',
        startBookingAt: 'Beginnen Sie mit der Buchung {date}',
        moreDepartures: 'weitere Reisetermine',
        moreDeparture: 'more departure',
        cruiseOverview : 'Ihre Reise in der Übersicht',
        excursionsActivities: 'Exkursionen & Aktivitäten',
        itinerary: 'Reiseplan',
        whatsIncluded: 'Was ist inbegriffen?',
        ottoSverdrup: 'Ab dem 19. Januar 2024 werden alle Reisen von MS Otto Sverdrup von Hurtigruten Norwegen durchgeführt.',
        nordstjernen: 'Ab 2024 werden alle Fahrten von MS Nordstjernen von Hurtigruten Norwegen durchgeführt.'
      },
      onboardActivities: 'Aktivitäten an Bord',
      landingActivities: 'Erkundungstouren',
      whatsNotIncluded: 'Nicht im Preis inbegriffen',
      itinerary: {
        selectTheDay: 'Wählen Sie den Tag aus',
        showRouteMap: 'Routenkarte ansehen',
        showGallery: 'Galerie anzeigen',
        checkDayToDayItinerary: 'Route von Tag zu Tag prüfen',
        routeOverview: 'Routenübersicht',
        routeOverviewDescription: 'Übersicht über den Reiseablauf'
      },
      temporarilyDisabled: {
        heading: 'Abreisedaten vorübergehend nicht verfügbar',
        sorry: 'Es tut uns leid, aber wir führen bis zum 13. September Wartungsarbeiten durch, die Ihr Konto, Ihre Kreuzfahrten und andere Funktionen betreffen.',
        contact: 'Wenn Sie Hilfe benötigen, wenden Sie sich bitte an den Kundendienst unter +41 800 563 059'
      }
    }
  },
  'fr-FR': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Aucun résultat Essayez de modifier votre recherche en supprimant certains filtres',
        pageTitle: 'Choisissez votre croisière',
        showingXExpeditionCruises: '{number} croisière(s) d’expédition proposée(s)',
        sortFeatured: 'Présent',
        sortNameAsc: 'Nom (de A à Z)',
        sortNameDesc: 'Nom (de Z à A)',
        sortPriceAsc: 'Prix (par ordre croissant)',
        sortPriceDesc: 'Prix (par ordre décroissant)',
        somethingWentWrong: 'Une erreur est survenue, veuillez réessayer plus tard.',
        tryOurMustSeeDestinations: 'Ou essayez nos destinations incontournables',
        advancedFilters: 'Filtres avancés',
        xSelectedFilters: 'filtres sélectionnés {filters}',
        seeMoreCruises: 'Voir plus de croisières d’expédition',
        xOfYCruises: '{x} sur {y} croisières',
        sortDepartureASC: 'Date de départ (de la plus proche à la plus éloignée)',
        sortDepartureDESC: 'Date de départ (de la plus éloignée à la plus proche)',
        pageSubtitle: 'Nous proposons de nombreuses croisières vers plusieurs destinations captivantes. Découvrez où vous pouvez aller à l’aide du filtre des destinations.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Autres suggestions de croisières',
        shipFallbackTitle: 'Navire(s) de cette expédition',
        seeShip: 'Voir le navire',
        unknownDestination: 'Destination inconnue',
        destinationsForExplorers: 'Des destinations pour les explorateurs',
        destinationsForExplorersSubtitle: 'Au plus près de la nature sauvage et au contact des communautés locales.',
        failedToLoad: 'Échec du chargement du voyage. Veuillez réessayer plus tard.',
        explorationTeamCaption: 'Les guides qui embarquent avec vous sont des experts locaux dans les domaines d’intérêt propre à votre destination : {destination}',
        whatIsIncluded: 'Prestations incluses',
        availablePromotions: 'Promotions disponibles',
        departureDates: 'Dates de départ',
        departuresBetween: '{numberOfDepartures} départs entre {startDate} et {endDate}',
        departureDate: 'Date de départ',
        includedActivities: {
          title: 'Activités incluses',
          subTitle: 'Il s’agit d\'activités destinées aux participants de tout âge'
        },
        returnDate: 'Date de retour',
        practicalInfoYouNeedToKnow: 'Informations pratiques',
        enhanceYourCruise: {
          title: 'Complétez votre croisière',
          subTitle: 'Apprenez-en davantage sur l’expérience qui vous attend en embarquant avec nous',
          excursionTab: 'Excursions',
          programTab: 'Pré/post-programmes'
        },
        lookForwardToCruise: 'Que comprend votre voyage ?',
        lookForwardToNDayCruise: 'Découvrez ce qui vous attend lors de cette croisière d’expédition de {duration} jours.',
        specialOffers: 'Offres spéciales et actualités',
        expeditionExclusiveCostSavings: 'Découvrez des réductions exclusives ou des avantages à bord sur nos croisières d\'expédition.',
        exclusiveCostSavingsFootnote: 'Nos offres sont cumulables uniquement avec la remise fidélité du programme 1893 Ambassador',
        shipSectionDescriptionText: 'Découvrez le(s) navire(s) qui effectue(nt) cette expédition',
        requestAQuote: 'Demander un devis',
        startBookingAt: 'Début des réservations le {date}',
        moreDepartures: 'dates de départ',
        moreDeparture: 'date de départ',
        cruiseOverview : 'Aperçu de la croisière',
        excursionsActivities: 'Excursions & activités ',
        itinerary: 'Itinéraire',
        whatsIncluded: 'Ce qui est inclus',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'À partir de 2024, toutes les croisières du MS Nordstjernen seront assurées par Hurtigruten Norway.'
      },
      onboardActivities: 'Activités à bord',
      landingActivities: 'Activités à terre',
      whatsNotIncluded: 'Prestations non incluses',
      itinerary: {
        selectTheDay: 'Sélectionner le jour',
        showRouteMap: 'Afficher la carte de l’itinéraire',
        showGallery: 'Afficher la galerie',
        checkDayToDayItinerary: 'Consulter l’itinéraire jour par jour',
        routeOverview: 'Aperçu de l’itinéraire',
        routeOverviewDescription: 'Découvrir les temps forts du voyage'
      },
      temporarilyDisabled: {
        heading: 'Dates de départ temporairement indisponibles',
        sorry: 'Nous sommes désolés, mais nous effectuons une maintenance jusqu\'au 13 septembre, ce qui affecte votre compte, vos croisières et d\'autres fonctionnalités.',
        contact: 'Pour obtenir de l\'aide, veuillez contacter le service clientèle au +33 182880759'
      }
    }
  },
  'da-DK': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Ingen resultater. Prøv at justere din søgning ved at fjerne filtre.',
        pageTitle: 'Find dit krydstogt',
        showingXExpeditionCruises: 'Viser {number} ekspeditionskrydstogter',
        sortFeatured: 'Udvalgte',
        sortNameAsc: 'Navn (A-Å)',
        sortNameDesc: 'Navn (Å-A)',
        sortPriceAsc: 'Pris (lav til høj)',
        sortPriceDesc: 'Pris (høj til lav)',
        somethingWentWrong: 'Der gik noget galt. Prøv igen senere',
        tryOurMustSeeDestinations: 'Eller prøv vores destinationer, du skal opleve',
        advancedFilters: 'Avancererede filtre',
        xSelectedFilters: '{filters} filtre valgt',
        seeMoreCruises: 'Se flere ekspeditionskrydstogter',
        xOfYCruises: '{x} af {y} krydstogter',
        sortDepartureASC: 'Afrejsedato (fjern til tæt)',
        sortDepartureDESC: 'Afrejsedato (tæt til fjern)',
        pageSubtitle: 'Vi har mange krydstogter til spændende destinationer. Brug destinationsfiltret til at se, hvor du kan rejse hen.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Relaterede ekspeditionsrejser',
        shipFallbackTitle: 'Skibe på denne ekspedition',
        seeShip: 'Se skibet',
        unknownDestination: 'Ukendt destination',
        destinationsForExplorers: 'Destinationer for eventyrere',
        destinationsForExplorersSubtitle: 'En blanding af uberørt vildmark og besøg i oprindelige lokale samfund.',
        failedToLoad: 'Det lykkedes ikke at indlæse rejsen. Prøv igen senere.',
        explorationTeamCaption: 'Dine rejseledere ombord er lokale områdeeksperter specifikt for {destination}.',
        whatIsIncluded: 'Inkluderet',
        availablePromotions: 'Tilgængelige kampagner',
        departureDates: 'Afrejsedatoer',
        departuresBetween: '{numberOfDepartures} afgange mellem {startDate} og {endDate}',
        departureDate: 'Afrejsedato',
        includedActivities: {
          title: 'Inkluderede aktiviteter',
          subTitle: 'Det er en blanding af oplevelser og aktiviteter for alle aldersgrupper'
        },
        returnDate: 'Returdato',
        practicalInfoYouNeedToKnow: 'Praktiske oplysninger, du har brug for at vide',
        enhanceYourCruise: {
          title: 'Aktiviteter og udflugter',
          subTitle: 'Læs mere om, hvad du kan opleve, når du rejser med os',
          excursionTab: 'Udflugter',
          programTab: 'Program før og efter sejladsen'
        },
        lookForwardToCruise: 'Se, hvad du kan glæde dig til på dette ekspeditionskrydstogt.',
        lookForwardToNDayCruise: 'Se, hvad du kan glæde dig til på dette {duration}-dages ekspeditionskrydstogt.',
        specialOffers: 'Tilbud',
        expeditionExclusiveCostSavings: 'Eksklusive besparelser og særlige tilbud.',
        exclusiveCostSavingsFootnote: 'Visse tilbud kan ikke kombineres. Du kan anvende op til to tilbud, medmindre andet er angivet',
        shipSectionDescriptionText: 'Se nærmere på vores skibe, som typisk bruges på denne ekspedition',
        requestAQuote: 'Få et tilbud',
        startBookingAt: 'Start bestilling {date}',
        moreDepartures: 'flere afgange',
        moreDeparture: 'flere afgange',
        cruiseOverview : 'Krydstogtet',
        excursionsActivities: 'Udflugter og aktiviteter',
        itinerary: 'Rejserute',
        whatsIncluded: 'Hvad der er inkluderet',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'Fra 2024 vil alle sejladser med MS Nordstjernen blive drevet af Hurtigruten Norge.'
      },
      onboardActivities: 'Aktiviteter ombord',
      landingActivities: 'Aktiviteter ved landgang',
      whatsNotIncluded: 'Ikke inkluderet',
      itinerary: {
        selectTheDay: 'Vælg dag',
        showRouteMap: 'Vis rutekort',
        showGallery: 'Vis galleri',
        checkDayToDayItinerary: 'Se rejseruten dag for dag',
        routeOverview: 'Oversigt over rute',
        routeOverviewDescription: 'Vi hjælper dig med at planlægge, hvor rejsen skal gå til, hvornår og hvor længe'
      },
      temporarilyDisabled: {
        heading: 'Afrejsedatoer midlertidigt utilgængelige',
        sorry: 'Vi beklager, men vi udfører vedligeholdelse indtil 13. september, hvilket påvirker din konto, krydstogter og andre funktioner.',
        contact: 'For hjælp, kontakt venligst kundeservice på +45 89875743'
      }
    }
  },
  'sv-SE': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Inga resultat. Försök anpassa sökningen genom att ta bort filtren.',
        pageTitle: 'Hitta kryssningen för dig',
        showingXExpeditionCruises: 'Alla våra {number} expeditionskryssningar',
        sortFeatured: 'Utvalda',
        sortNameAsc: 'Namn (A–Ö)',
        sortNameDesc: 'Namn (Ö–A)',
        sortPriceAsc: 'Pris (lågt till högt)',
        sortPriceDesc: 'Pris (högt till lågt)',
        somethingWentWrong: 'Det uppstod ett fel. Försök igen senare.',
        tryOurMustSeeDestinations: 'Eller försök med våra resmål som du inte får missa',
        advancedFilters: 'Avancerade filter',
        xSelectedFilters: '{filters} valda filter',
        seeMoreCruises: 'Visa fler expeditionskryssningar',
        xOfYCruises: '{x} av {y} kryssningar',
        sortDepartureASC: 'Avgångsdatum (stigande)',
        sortDepartureDESC: 'Avgångsdatum (fallande)',
        pageSubtitle: 'Vi har kryssningar till många spännande platser. Använd filtret för resmål för att se vart du kan resa.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Liknande kryssningar',
        shipFallbackTitle: 'Fartyg på den här expeditionen',
        seeShip: 'Visa fartyg',
        unknownDestination: 'Okänt resmål',
        destinationsForExplorers: 'Resmål för äventyrare',
        destinationsForExplorersSubtitle: 'En blandning av orörd natur och möten med människor i deras lokalsamhällen.',
        failedToLoad: 'Det gick inte att läsa in resan. Försök igen senare.',
        explorationTeamCaption: 'Dina guider ombord är lokala experter på ämnen relaterade till {destination}',
        whatIsIncluded: 'Vad ingår?',
        availablePromotions: 'Pågående kampanjer',
        departureDates: 'Avgångsdatum',
        departuresBetween: '{numberOfDepartures} avgångar mellan {startDate} och {endDate}',
        departureDate: 'Avgångsdatum',
        includedActivities: {
          title: 'Program som ingår',
          subTitle: 'Det är en blandning av äventyrsaktiviteter som passar alla åldrar.'
        },
        returnDate: 'Datum för återresa',
        practicalInfoYouNeedToKnow: 'Praktisk information som du behöver veta',
        enhanceYourCruise: {
          title: 'Ge din kryssning det lilla extra',
          subTitle: 'Läs mer om allt du kan uppleva när du reser med oss',
          excursionTab: 'Utflykter',
          programTab: 'Program före och efter resa'
        },
        lookForwardToCruise: 'Ta en titt på vad du har att se fram emot på den här expeditionskryssningen.',
        lookForwardToNDayCruise: 'Ta en titt på vad du har att se fram emot på den här {duration} dagar långa expeditionskryssningen.',
        specialOffers: 'Erbjudanden',
        expeditionExclusiveCostSavings: 'Exklusive kostnadsbesparingar och sällsynta möjligheter.',
        exclusiveCostSavingsFootnote: 'Vissa erbjudanden kan eventuellt inte kombineras. Upp till två erbjudanden såvida något annat inte anges.',
        shipSectionDescriptionText: 'Ta en närmare titt på de fartyg som normalt sett används för den här expeditionen',
        requestAQuote: 'Begär en offert',
        startBookingAt: 'Påbörja bokning {date}',
        moreDepartures: 'fler avgångar',
        moreDeparture: 'fler avgångar',
        cruiseOverview : 'Översikt över kryssningen',
        excursionsActivities: 'Utflykter och aktiviteter',
        itinerary: 'Resplan',
        whatsIncluded: 'Vad som ingår',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'Från 2024 kommer alla seglingar med MS Nordstjernen att skötas av Hurtigruten Norge. '
      },
      onboardActivities: 'Aktiviteter ombord',
      landingActivities: 'Aktiviteter på land',
      whatsNotIncluded: 'Detta ingår inte',
      itinerary: {
        selectTheDay: 'Välj dag',
        showRouteMap: 'Visa ruttkarta',
        showGallery: 'Visa galleri',
        checkDayToDayItinerary: 'Visa daglig resplan',
        routeOverview: 'Ruttöversikt',
        routeOverviewDescription: 'Vi hjälper dig att planera vart resan ska gå, när den ska ske och hur länge den ska vara'
      },
      temporarilyDisabled: {
        heading: 'Avresedatum är tillfälligt inte tillgängliga',
        sorry: 'Vi är ledsna, men vi utför underhåll fram till den 13 september, vilket påverkar ditt konto, kryssningar och andra funktioner.',
        contact: 'För hjälp, vänligen kontakta kundtjänst på +46 844687060'
      }
    }
  },
  'nb-NO': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'Ingen resultater. Prøv å endre søket ditt ved å fjerne filtre',
        pageTitle: 'Finn drømmereisen din',
        showingXExpeditionCruises: 'Viser {number} ekspedisjonscruise',
        sortFeatured: 'Utvalgte',
        sortNameAsc: 'Navn (A–Å)',
        sortNameDesc: 'Navn (Å–A)',
        sortPriceAsc: 'Pris (lav til høy)',
        sortPriceDesc: 'Pris (høy til lav)',
        somethingWentWrong: 'Noe gikk galt, prøv igjen senere',
        tryOurMustSeeDestinations: 'Eller sjekk ut destinasjonene våre som må oppleves',
        advancedFilters: 'Avanserte filter',
        xSelectedFilters: '{filters} filtre valgt',
        seeMoreCruises: 'Se flere ekspedisjonscruise',
        xOfYCruises: '{x} av {y} cruise',
        sortDepartureASC: 'Avreisedato (fra nært forestående til langt frem i tid)',
        sortDepartureDESC: 'Avreisedato (fra langt frem i tid til nært forestående)',
        pageSubtitle: 'Vi har mange cruisereiser til spennende destinasjoner. Bruk destinasjonsfilteret for å se hvor du kan reise.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Relaterte ekspedisjonscruise',
        shipFallbackTitle: 'Skip på denne ekspedisjonen',
        seeShip: 'Se skip',
        unknownDestination: 'Ukjent destinasjon',
        destinationsForExplorers: 'Destinasjoner for deg som liker å utforske verden',
        destinationsForExplorersSubtitle: 'En blanding av uberørt natur og besøk hos autentiske lokalsamfunn.',
        failedToLoad: 'Kunne ikke laste inn reisen. Prøv på nytt senere.',
        explorationTeamCaption: 'Guidene dine om bord er lokale eksperter innen {destination}',
        whatIsIncluded: 'Inkludert',
        availablePromotions: 'Aktuelle tilbud',
        departureDates: 'Avreisedatoer',
        departuresBetween: '{numberOfDepartures} avreiser mellom {startDate} og {endDate}',
        departureDate: 'Avreisedato',
        includedActivities: {
          title: 'Inkluderte programmer',
          subTitle: 'En blanding av eventyrlige aktiviteter for barn og voksne i alle aldre'
        },
        returnDate: 'Returdato',
        practicalInfoYouNeedToKnow: 'Dette bør du vite: Praktisk informasjon',
        enhanceYourCruise: {
          title: 'Ta reisen til nye høyder',
          subTitle: 'Finn ut mer om hva du kan oppleve når du reiser med oss',
          excursionTab: 'Utflukter',
          programTab: 'Før- og etterprogrammer'
        },
        lookForwardToCruise: 'Ta en titt på hva du kan se frem til på dette ekspedisjonscruiset.',
        lookForwardToNDayCruise: 'Ta en titt på hva du kan se frem til på dette {duration}-dagers ekspedisjonscruiset.',
        specialOffers: 'Tilbud',
        expeditionExclusiveCostSavings: 'Eksklusive besparelser og tilbud. ',
        exclusiveCostSavingsFootnote: 'Noen tilbud kan kanskje ikke kombineres. Du kan bruke opp til to tilbud hvis ikke noe annet er oppgitt',
        shipSectionDescriptionText: 'Ta en titt på skipene som vanligvis brukes på denne ekspedisjonen',
        requestAQuote: 'Be om pristilbud',
        startBookingAt: 'Start bestillingen {date}',
        moreDepartures: 'flere avreiser',
        moreDeparture: 'flere avreiser',
        cruiseOverview : 'Oversikt',
        excursionsActivities: 'Utflukter og aktiviteter',
        itinerary: 'Reiserute',
        whatsIncluded: 'Hva er inkludert',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'Fra 2024 vil alle seilinger med MS Nordstjernen bli operert av Hurtigruten Norge. '
      },
      onboardActivities: 'Aktiviteter om bord',
      landingActivities: 'Aktiviteter på land',
      whatsNotIncluded: 'Dette er ikke inkludert',
      itinerary: {
        selectTheDay: 'Velg dag',
        showRouteMap: 'Vis kart over reiseruten',
        showGallery: 'Vis bilder',
        checkDayToDayItinerary: 'Se reiseplanen dag for dag',
        routeOverview: 'Oversikt over reiseruten',
        routeOverviewDescription: 'Vi hjelper deg med å planlegge hvor og når du skal reise, og hvor lenge turen skal vare'
      },
      temporarilyDisabled: {
        heading: 'Avreisedatoer midlertidig utilgjengelige',
        sorry: 'Vi beklager, men vi utfører vedlikehold frem til 13. september, noe som påvirker kontoen din, cruisene dine og andre funksjoner.',
        contact: 'For hjelp, vennligst kontakt kundeservice på +47 75987787'
      }
    }
  },
  'en': {
    voyage: {
      catalogue: {
        noMoreVoyages: 'No results. Try adjusting your search by removing filters',
        pageTitle: 'Find Your Cruise',
        showingXExpeditionCruises: 'Showing {number} expedition cruise(s)',
        sortFeatured: 'Featured',
        sortNameAsc: 'Name (A-Z)',
        sortNameDesc: 'Name (Z-A)',
        sortPriceAsc: 'Price (low to high)',
        sortPriceDesc: 'Price (high to low)',
        somethingWentWrong: 'Something went wrong, please try again later',
        tryOurMustSeeDestinations: 'Or try our must-see destinations',
        advancedFilters: 'Advanced filters',
        xSelectedFilters: '{filters} selected filters',
        seeMoreCruises: 'See more expedition cruises',
        xOfYCruises: '{x} of {y} cruises',
        sortDepartureASC: 'Departure date (near to far)',
        sortDepartureDESC: 'Departure date (far to near)',
        pageSubtitle: 'We have many cruises to several exciting destinations. Use the destination filter to see where you can go.',
        sort: 'Sort'
      },
      details: {
        relatedVoyagesTitle: 'Related cruises',
        shipFallbackTitle: 'Ships on this expedition',
        seeShip: 'See ship',
        unknownDestination: 'Unknown destination',
        destinationsForExplorers: 'Destinations for explorers',
        destinationsForExplorersSubtitle: 'A mix of pristine wilderness and visits to genuine local communities.',
        failedToLoad: 'Failed to load voyage. Please try again later.',
        explorationTeamCaption: 'Your onboard guides are local experts in areas of interest specific to {destination}',
        whatIsIncluded: 'What\'s included',
        availablePromotions: 'Available Promotions',
        departureDates: 'Departure Dates',
        departuresBetween: '{numberOfDepartures} departures between {startDate} and {endDate}',
        departureDate: 'Departure date',
        includedActivities: {
          title: 'Included Programmes',
          subTitle: 'It\'s a mixture of adventure activities for individuals of all ages'
        },
        returnDate: 'Return date',
        practicalInfoYouNeedToKnow: 'Practical information you need to know',
        enhanceYourCruise: {
          title: 'Enhance your cruise',
          subTitle: 'Learn more about what you can experience when going with us',
          excursionTab: 'Excursions',
          programTab: 'Pre-post programmes'
        },
        lookForwardToCruise: 'Take a look at what you can look forward to on this expedition cruise.',
        lookForwardToNDayCruise: 'Take a look at what you can look forward to on this {duration}-day expedition cruise.',
        specialOffers: 'Special offers',
        expeditionExclusiveCostSavings: 'Expedition exclusive cost savings and rare opportunities.',
        exclusiveCostSavingsFootnote: 'Certain offers may not be combinable, up to two savings opportunities except where noted otherwise',
        shipSectionDescriptionText: 'Take a closer look at our ships typically used on this expedition',
        requestAQuote: 'Request a quote',
        startBookingAt: 'Start booking {date}',
        moreDepartures: 'more departures',
        moreDeparture: 'more departure',
        cruiseOverview : 'Cruise Overview',
        excursionsActivities: 'Excursions & Activities ',
        itinerary: 'Itinerary',
        whatsIncluded: 'What\'s Included',
        ottoSverdrup: 'From 19 January 2024, all MS Otto Sverdrup sailings will be operated by Hurtigruten Norway.',
        nordstjernen: 'From 2024, all sailings on MS Nordstjernen will be operated by Hurtigruten Norway. '
      },
      onboardActivities: 'Onboard activities',
      landingActivities: 'Landing activities',
      whatsNotIncluded: 'What\'s not included',
      itinerary: {
        selectTheDay: 'Select the day',
        showRouteMap: 'Show Route Map',
        showGallery: 'Show gallery',
        checkDayToDayItinerary: 'Check day-to-day itinerary',
        routeOverview: 'Route overview',
        routeOverviewDescription: 'We\'ll help you plan where to go, when to visit and how long to stay'
      },
      temporarilyDisabled: {
        heading: 'Departure dates temporarily unavailable',
        sorry: 'We’re sorry, but we are performing maintenance until September 13th, which affects your account, cruises and other features.',
        contact: 'For help, please contact Customer Services on +44 2081311464'
      }
    }
  }
}

export type TVoyage = {
  voyage: {
    catalogue: {
      noMoreVoyages: string;
      pageTitle: string;
      showingXExpeditionCruises: string;
      sortFeatured: string;
      sortNameAsc: string;
      sortNameDesc: string;
      sortPriceAsc: string;
      sortPriceDesc: string;
      somethingWentWrong: string;
      tryOurMustSeeDestinations: string;
      advancedFilters: string;
      xSelectedFilters: string;
      seeMoreCruises: string;
      xOfYCruises: string;
      sortDepartureASC: string;
      sortDepartureDESC: string;
      pageSubtitle: string;
      sort: string;
    };
    details: {
      relatedVoyagesTitle: string;
      shipFallbackTitle: string;
      seeShip: string;
      unknownDestination: string;
      destinationsForExplorers: string;
      destinationsForExplorersSubtitle: string;
      failedToLoad: string;
      explorationTeamCaption: string;
      whatIsIncluded: string;
      availablePromotions: string;
      departureDates: string;
      departuresBetween: string;
      departureDate: string;
      includedActivities: {
        title: string;
        subTitle: string;
      };
      returnDate: string;
      practicalInfoYouNeedToKnow: string;
      enhanceYourCruise: {
        title: string;
        subTitle: string;
        excursionTab: string;
        programTab: string;
      };
      lookForwardToCruise: string;
      lookForwardToNDayCruise: string;
      specialOffers: string;
      expeditionExclusiveCostSavings: string;
      exclusiveCostSavingsFootnote: string;
      shipSectionDescriptionText: string;
      requestAQuote: string;
      startBookingAt: string;
      moreDepartures: string;
      moreDeparture: string;
      cruiseOverview : string;
      excursionsActivities: string;
      itinerary: string;
      whatsIncluded: string;
      ottoSverdrup: string;
      nordstjernen: string;
    };
    onboardActivities: string;
    landingActivities: string;
    whatsNotIncluded: string;
    itinerary: {
      selectTheDay: string;
      showRouteMap: string;
      showGallery: string;
      checkDayToDayItinerary: string;
      routeOverview: string;
      routeOverviewDescription: string;
    };
    temporarilyDisabled: {
      heading: string;
      sorry: string;
      contact: string;
    };
  };
}
